exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutmsocb-js": () => import("./../../../src/pages/aboutmsocb.js" /* webpackChunkName: "component---src-pages-aboutmsocb-js" */),
  "component---src-pages-contact-form-js": () => import("./../../../src/pages/contact-form.js" /* webpackChunkName: "component---src-pages-contact-form-js" */),
  "component---src-pages-contact-page-js": () => import("./../../../src/pages/contact-page.js" /* webpackChunkName: "component---src-pages-contact-page-js" */),
  "component---src-pages-home-text-js": () => import("./../../../src/pages/home-text.js" /* webpackChunkName: "component---src-pages-home-text-js" */),
  "component---src-pages-homegallery-js": () => import("./../../../src/pages/homegallery.js" /* webpackChunkName: "component---src-pages-homegallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-latest-update-js": () => import("./../../../src/pages/latest-update.js" /* webpackChunkName: "component---src-pages-latest-update-js" */),
  "component---src-pages-my-path-js": () => import("./../../../src/pages/my-path.js" /* webpackChunkName: "component---src-pages-my-path-js" */),
  "component---src-pages-nop-js": () => import("./../../../src/pages/nop.js" /* webpackChunkName: "component---src-pages-nop-js" */),
  "component---src-pages-notice-detail-js": () => import("./../../../src/pages/notice-detail.js" /* webpackChunkName: "component---src-pages-notice-detail-js" */),
  "component---src-pages-notification-js": () => import("./../../../src/pages/Notification.js" /* webpackChunkName: "component---src-pages-notification-js" */),
  "component---src-pages-organization-structure-js": () => import("./../../../src/pages/Organization-structure.js" /* webpackChunkName: "component---src-pages-organization-structure-js" */),
  "component---src-pages-photo-gallery-js": () => import("./../../../src/pages/photo-gallery.js" /* webpackChunkName: "component---src-pages-photo-gallery-js" */),
  "component---src-pages-quick-link-acessibility-js": () => import("./../../../src/pages/Quick-link/acessibility.js" /* webpackChunkName: "component---src-pages-quick-link-acessibility-js" */),
  "component---src-pages-quick-link-copy-right-js": () => import("./../../../src/pages/Quick-link/copy-right.js" /* webpackChunkName: "component---src-pages-quick-link-copy-right-js" */),
  "component---src-pages-quick-link-disclaimer-js": () => import("./../../../src/pages/Quick-link/disclaimer.js" /* webpackChunkName: "component---src-pages-quick-link-disclaimer-js" */),
  "component---src-pages-quick-link-policy-js": () => import("./../../../src/pages/Quick-link/policy.js" /* webpackChunkName: "component---src-pages-quick-link-policy-js" */),
  "component---src-pages-quick-link-screenreader-js": () => import("./../../../src/pages/Quick-link/screenreader.js" /* webpackChunkName: "component---src-pages-quick-link-screenreader-js" */),
  "component---src-pages-quick-link-term-js": () => import("./../../../src/pages/Quick-link/term.js" /* webpackChunkName: "component---src-pages-quick-link-term-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-training-capacity-js": () => import("./../../../src/pages/training-capacity.js" /* webpackChunkName: "component---src-pages-training-capacity-js" */),
  "component---src-pages-whoiswho-js": () => import("./../../../src/pages/whoiswho.js" /* webpackChunkName: "component---src-pages-whoiswho-js" */),
  "component---src-templates-displaybasic-page-js": () => import("./../../../src/templates/displaybasic-page.js" /* webpackChunkName: "component---src-templates-displaybasic-page-js" */),
  "component---src-templates-notice-detail-js": () => import("./../../../src/templates/Notice-detail.js" /* webpackChunkName: "component---src-templates-notice-detail-js" */),
  "component---src-templates-training-detial-js": () => import("./../../../src/templates/training-detial.js" /* webpackChunkName: "component---src-templates-training-detial-js" */)
}

